<template>
    <div>
        <Pane />

        <div class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="分类">
                            <a-select style="width: 100%" :disabled="true" v-model="activeCategoryIndex">
                                <a-select-option v-for="(item, index) in categoryList" :key="index" :value="index">{{ item
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="子分类">
                            <a-select style="width: 100%" :disabled="true" v-decorator="[
                                'pid',
                                {
                                    rules: [{ required: true, message: '请选择！' }],
                                },
                            ]">
                                <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">{{
                                    item.subCategory
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="名称">
                            <a-input v-decorator="[
                                'name',
                                {
                                    initialValue: detail.name,

                                    rules: [{ required: true, message: '请输入！' }],
                                },
                            ]" />
                        </a-form-item>
                    </a-col>


                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="说明" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
                            <a-textarea :auto-size="{ minRows: 3 }" v-decorator="[
                                'remark',
                                {
                                    initialValue: detail.remark,
                                },
                            ]" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
                            <QiniuUpload :list="attachmentList" :token="token" @change="uploaded" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <div class="center">
                    <a-space>
                        <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
                        <a-button @click="$close($route.path)">关闭</a-button>
                    </a-space>
                </div>
            </a-form>
        </div>
    </div>
</template>
  
<script>
import { fetchCategory, fetchDetail, edit } from "@/api/knowledge";

import QiniuUpload from "./components/qiniu-upload.vue";

import {
    fetchToken,
} from '@/api/knowledge/qiniu'

import moment from "moment";
export default {
    name: "editBrand",

    components: {
        QiniuUpload,
    },

    data() {
        return {
            form: this.$form.createForm(this),

            id: null,
            pid: null,
            detail: {},

            attachmentList: [],

            category: {},
            categoryList: [],
            activeCategoryIndex: 0,

            loading: false,

            token: '', // 七牛文件上传token
        };
    },

    computed: {
        typeList() {
            const list = this.category[this.categoryList[this.activeCategoryIndex]];
            return Array.isArray(list) ? list : [];
        },

    },

    activated() {
        const { type } = this.$route.query;
        fetchCategory({
            field: type
        }).then((res) => {
            if (res) {
                this.category = res;
                this.categoryList = Object.keys(res);

                const { query } = this.$route;
                const { id, pid } = query || {};
                if (id && pid && id !== this.id && pid !== this.pid) {
                    this.id = id;
                    this.pid = pid;
                    this.detail = {};
                    fetchDetail({ id, pid }).then((res) => {
                        this.detail = res ?? {};
                        this.form.setFieldsValue({
                            pid: res.pid,
                        });

                        this.categoryList.forEach((item, index) => {
                            if (Array.isArray(this.category[item])) {
                                const result = this.category[item].find(
                                    (element) => element.id === res.pid
                                );
                                if (result) {
                                    this.activeCategoryIndex = index;
                                }
                            }
                        });

                        if (Array.isArray(res.attachmentList)) {
                            this.attachmentList = res.attachmentList;
                        }
                        if (Array.isArray(res.docQcResearchAchievment)) {
                            this.achievementList = res.docQcResearchAchievment;
                        }
                    });
                }
            }
        });

        fetchToken().then(res => {
            console.log('token', res)
            if (res) {
                this.token = res;
            }
        })

    },

    methods: {
        moment,


        uploaded(list) {
            this.attachmentList = list;
        },

        handleSubmit(e) {
            e.preventDefault();

            this.form.validateFields((err, values) => {
                if (!err) {

                    if (this.attachmentList.filter(item => item.error).length > 0) {
                        this.$message.error('请先删除上传失败的文件')
                        return;
                    }
                    if (this.attachmentList.filter(item => item.progress > 0 && item.progress < 100 && !item.hash).length > 0) {
                        this.$message.error('请等待文件上传成功')
                        return;
                    }


                    this.loading = true;


                    edit({
                        ...this.detail,
                        ...values,

                        attachmentList:
                            this.attachmentList.length > 0 ? this.attachmentList : undefined,

                        // 重置掉后端不要的数据
                        updateAt: undefined,
                        updateBy: undefined,
                        createBy: undefined,
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.container {
    background-color: #fff;
    padding: 30px 164px;
}

.center {
    margin-top: 91px;
}
</style>
  