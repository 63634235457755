<template>
    <div>

        <div>
            <input ref="upload" style="display: none" type="file" :multiple="true" @change="onChange" />
            <a-space>
                <a-button type="primary" :loading="loading" @click="onClick">
                    {{ list.length > 0 ? '继续上传' : '点击上传' }}
                </a-button>
                <span style="color: #999">大文件上传时间较长，请耐心等待</span>
            </a-space>
        </div>

        <Padding />

        <a-table bordered :data-source="list" :pagination="false">
            <a-table-column title="文件名">
                <template slot-scope="text">
                    <a v-if="text.completePath" :href="text.completePath" target="_blank">{{
                        text.name
                    }}</a>
                    <span v-else>{{ text.name }}</span>
                </template>
            </a-table-column>
            <a-table-column title="进度">
                <template slot-scope="text">
                    <span v-if="text.completePath">
                        <a-progress :percent="100" size="small" />
                    </span>
                    <span v-else>
                        <a-progress :percent="text.progress ? text.progress : 0" size="small" />
                    </span>
                </template>
            </a-table-column>
            <a-table-column title="状态" align="center">
                <template slot-scope="text">
                    <a-badge status="error" v-if="text.error" text="上传错误" />
                    <a-badge status="success" v-else-if="text.progress === 100 || text.completePath" text="上传成功" />
                    <a-badge status="processing" v-else text="上传中" />
                </template>
            </a-table-column>

            <a-table-column title="操作" align="center">
                <template slot-scope="text, record, index">
                    <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
                </template>
            </a-table-column>
        </a-table>

    </div>
</template>
  
<script>

import {
    fetchFileKey,
    updateFile,
} from '@/api/knowledge/qiniu'

import * as qiniu from "qiniu-js";

export default {
    props: {
        token: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        onClick() {
            this.$refs.upload.click();
        },

        onChange(e) {
            const files = e.target.files;

            console.log('files', files)

            const params = {
                names: []
            }

            let flag = false;
            for (let i = 0; i < files.length; i++) {
                const name = files[i].name;
                if (this.list.find(item => item.name === name)) {
                    flag = true;
                } else {
                    params.names.push(name)

                }
            }

            if (flag) {
                this.$message.error('请勿重新上传文件')
                return;
            }

            if (params.length === 0) return;


            this.loading = true;

            fetchFileKey(params).then(names => {
                console.log("names", names)

                if (names) {

                    const arr = []

                    Object.keys(names).forEach(originalName => {
                        for (let i = 0; i < files.length; i++) {
                            const item = files[i];
                            if (item.name === originalName) {
                                arr.push({
                                    file: item,
                                    name: item.name,
                                    key: names[originalName]
                                })
                                break;
                            }
                        }
                    })

                    const newList = [
                        ...this.list,
                        ...arr.map(item => {
                            return {
                                name: item.name,
                                size: item.file.size,
                                mime: item.file.type,
                                key: item.key,
                                progress: 0,
                                file: item.file,
                            }
                        })
                    ]

                    this.$emit('change', [
                        ...newList.map((item, index) => {
                            let subscription;
                            if (item.progress === 0) {
                                subscription = this.upload2QiNiu(item.file, item.key, item.name, index)
                            }
                            return {
                                ...item,
                                subscription,
                            }
                        })
                    ])

                }

            }).finally(() => {
                e.target.value = null;
                this.loading = false;

            })

        },

        upload2QiNiu(file, key, name, index) {
            if (!this.token) {
                alert("没有上传令牌，请刷新页面重试");
                return;
            }

            const that = this;

            const observable = qiniu.upload(file, key, this.token);
            return observable.subscribe({
                next(res) {
                    console.log("res", res);
                    if (res.total) {

                        const list = [...that.list];
                        const item = list[index]
                        list.splice(index, 1, {
                            ...item,
                            progress: Math.round(res.total.percent),
                            uploadInfo: res,
                        })
                        that.$emit('change', list)

                    }
                },
                error(err) {
                    console.log("err", err);

                    const list = [...that.list];
                    const item = list[index]
                    list.splice(index, 1, {
                        ...item,
                        error: true,
                    })
                    that.$emit('change', list)
                },
                complete(res) {
                    console.log("complete res", res);

                    const list = [...that.list];
                    const item = list[index]

                    const saveNameArr = item.key.split('/');
                    const saveName = saveNameArr[saveNameArr.length - 1];

                    updateFile([{
                        name,
                        saveName,
                        savePath: item.key,
                        size: item.size,
                        mime: item.mime,

                    }]).then(res => {
                        if (res && res[name]) {
                            const newItem = {
                                ...item,
                                ...res[name]
                            }

                            list.splice(index, 1, newItem)
                            that.$emit('change', list)

                        }
                    })



                },
            });
        },


        remove(index) {
            const item = this.list[index]
            if (item.progress > 0 && item.progress < 100 && item.subscription) {
                item.subscription.unsubscribe();
            }
            // else if (item.progress === 100 && item.hash) {
            //     qiniu.deleteUploadedChunks(this.token, item.key, item.uploadInfo)
            // }
            const arr = [...this.list];
            arr.splice(index, 1);
            this.$emit('change', arr)
        },




    },
};
</script>